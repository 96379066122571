// const mockData1 = [
//   {
//     companyName: "公司名称",
//     list: [
//       {
//         "warehouseId": "789ee179c174b66ce9f6bb5c231dab3b",
//         "warehouseName": "万纬北京大兴园区",
//         "point":[longitudem, longitude],
//         "longitude": "116.590669",
//         "latitude": "39.715872",
//         "area": "面积",
//         "adress": "地址",
//       },
//     ]
//   },

// ] 


export const color = [
  "#b30d34",  //0
  "#61b087",  //1
  "#6d48e5",  //2
  "#dd868c",  //3
  "#d79f1a",  //4  
  "#95d05c",  //5
  "#3eede7",  //6
  "#813d32",  //7
  "#3271ae",  //8
  "#45465e",  //9
  "#ccc",  //10
  "#99bcac",  //11
  "#99bcac",  //12
  "#99bcac",  //13
  "#99bcac"   //14
]


export const mockData = [
  {
    "warehouseId": "789ee179c174b66ce9f6bb5c231dab3b",
    "warehouseName": "万纬北京大兴园区",
    "longitude": "116.590669",
    "latitude": "39.715872"
  },
  {
    "warehouseId": "dc189854a5ff4d13bc16ca56869c3f0e",
    "warehouseName": "体资展股份有限公司沈阳铁国公司(长自配送中心)",
    "longitude": "116.446578",
    "latitude": "39.543452"
  },
  {
    "warehouseId": "39ea3afe62fb91cd715604c16eee2147",
    "warehouseName": "万纬北京亦庄园区",
    "longitude": "116.536658",
    "latitude": "39.820247"
  },

  {
    "warehouseId": "7b1fc03084e366bb01a8aa022a8fe520",
    "warehouseName": "北京众惠供应链管理有限公司",
    "longitude": "116.586027",
    "latitude": "39.769251",
  },
  {
    "warehouseId": "fd4c29de44ceccfcbdea9702c52ed3ef",
    "warehouseName": "北京三惠物流有限公司",
    "longitude": "116.589642",
    "latitude": "39.774315",
  },

  {
    "warehouseId": "4cea923b99e8aee01cf07bfd3d99b9e8",
    "warehouseName": "京东智慧产业园-北京亦庄",
    "longitude": "116.60428",
    "latitude": "39.774229",
  },
  {
    "warehouseId": "d4279c844202cd20d7af65d51c4fb142",
    "warehouseName": "京东产发北京密云园区",
    "longitude": "116.809631",
    "latitude": "40.34214",
  }

]

