<template>
  <section class="viewport">
    <amap :zoom.sync="zoom" :zooms="zooms" :center.sync="center" :mapStyle="mapStyle" ref="myMap">
      <amap-marker
        v-for="(aoi, index) in mapData"
        v-if="modalValue == 'on'" 
        :key="index"
        :position="[aoi.longitude, aoi.latitude]"
        @click="onMarkerClick(aoi)"
      >
        <div class="mark-point" :style="{ backgroundColor: aoi.color }">
          <i class="el-icon-location-information"></i>
        </div>
      </amap-marker>

      <amap-marker-cluster
        key="custom-cluster"
        key-prop="poiid"
        :maxZoom="12"
        :data="mapData"
        :marker-options="getMarkerOptions"
        :cluster-options="getClusterOptions"
        :grid-size="options.gridSize"
        :average-center="options.averageCenter"
        v-if="modalValue == 'off'"
      >
        <template v-slot:cluster="context">
          <div :style="getClusterStyle(context)">
            {{ context.count }}
          </div>
        </template>
        <template v-slot:marker="point">
          <div
            class="mark-point"
            :style="{ backgroundColor: point.color }"
            @click="onMarkerClick(point)"
          >
            <i class="el-icon-location-information"></i>
          </div>
        </template>
      </amap-marker-cluster>

      <amap-info-window :position="activeAoi ? activeAoi : null" :visible.sync="visible" :offset="modalValue == 'on' ? [0, -5]:[0, -20]" is-custom auto-move closeWhenClickMap :key="visible">
        <div class="popup-container" v-if="activeAoi">
          <div class="title-inner">
            <span>{{ activeData.warehouseName }}</span>
            <i class="el-icon-close" @click="closePopup"></i>
          </div>
          <ul class="popup-inner">
            <li class="item-inner">
              <span>运营单位：</span>
              <span>{{ activeData.operationUnit }}</span>
            </li>
            <li class="item-inner">
              <span>占地面积：</span>
              <span>{{ activeData.area }} ㎡</span>
            </li>
            <li class="item-inner">
              <span>仓库地址：</span>
              <span>{{ activeData.address }}</span>
            </li>
            <li class="item-inner">
              <span>仓库概要：</span>
              <span>{{ activeData.desc}}</span>
            </li>
          </ul>
        </div>
      </amap-info-window>
    </amap>

    <!-- 搜索 -->
    <div class="search-container">
      <el-select v-model="searchVal" placeholder="请选择" size="small" @change="changSelect" popper-class="custom-popper">
        <el-option label="全部" value="" style="fontSize: 14px;"></el-option>
        <el-option
          v-for="item in clusterData"
          :key="item.index"
          :label="item.groupName"
          :value="item.index"
        >
          <div style="display: flex; align-items: center; justify-content: space-between;">
            <span style="padding-right: 10px; font-size: 14px;">{{ item.groupName }}</span>
            <span>{{ item.listSize }} 家</span>
            <!-- <span :style="{
              display: 'block',
              width: '20px',
              height: '4px',
              fontSize: '14px',
              backgroundColor: item.color
            }">{{ item.value }}</span> -->
          </div>
        </el-option>
    </el-select>
    </div>

    <!-- 图列 -->
    <div class="collapse-container">
      <div class="header">
        <img src="@/assets/logo.png" alt="" style="width: 80px; height: 30px;">
        <!-- <span></span> -->
        <el-switch
          v-model="modalValue"
          active-text="分散"
          active-color="#13ce66"
          active-value="on"
          inactive-text="聚合"
          inactive-color="#ff4949"
          inactive-value="off"
          @change="switchChage"
          >
        </el-switch>
      </div>

      <ul class="collapse-inner">
        <li class="collapse-item" v-for="(item, index) in groupList" :key="item.index">
          <p class="warehouseName">
            <!-- <span>No.{{ index + 1 }}</span> -->
            <span>{{ item.groupName }}</span>
          </p>
          <el-progress :percentage="item.percentage" :color="color[index]" :format="(a) => formatPercentage(a, item)"></el-progress>
        </li>
        <el-divider v-if="clusterData.length > 10" style="margin: 10px 0;"></el-divider>
        <li class="collapse-item" v-if="clusterData.length > 10">
          <p class="warehouseName">
            <span>其他</span>
          </p>
          <el-progress :percentage="otherInfo.percentage" :color="otherInfo.color" :format="(a) => formatPercentage(a, otherInfo)"></el-progress>
        </li>
      </ul>

    </div>
  </section>
</template>

<script>
  import { color } from "./config.js";

  const mapBaseConfigurate = {
    zoom: 5,
    zooms:[5, 13],
    mapStyle: "amap://styles/whitesmoke",
    center: [111.405285, 34.904989]
  }

  const originCenter = Object.freeze(mapBaseConfigurate.center);

  function interpolate(u, begin, end) {
    if (u < 0) u = 0;
    if (u > 1) u = 1;
    // u = Math.pow(u, 1 / 10);
    u = Math.pow(u, 1 / 5);
    return u * (end - begin) + begin;
  }

  export default {
    data() {
      return {
        ...mapBaseConfigurate,
        color,
        modalValue: "off",
        options: {
          gridSize: 100,
          averageCenter: true,
          zoomOnClick: true
        },
        activeAoi: null,
        activeData: {},
        visible: false,
        searchVal: "",
        // 
        originData: {},

        clusterData: [],
        mapData: [],
        groupList: [],
        otherInfo: {}
      }
    },
    methods: {
      changSelect(index) {
        const { mapData } = this.originData;

        if (index === '') {
          this.mapData = mapData;
        } else{
          this.mapData = mapData.filter(item => item.pId == index);
        }

        this.switchChage();
      },
      formatPercentage(percentage, item) {
        return item.listSize ? item.listSize + "家" : "";
      },
      // 关闭弹窗
      closePopup() {
        this.visible = false;
        this.activeAoi = null;
        this.activeData = {};
      },
      // maker点击
      onMarkerClick(aoi) {
        this.visible = true;
        this.activeAoi = [aoi.longitude, aoi.latitude];
        this.activeData = aoi;
      },
      // 切换显示模式
      switchChage(state) {
        this.zoom =  5;
        this.center = [...originCenter];
        this.closePopup();
      },
      getClusterStyle(context) {
        const u = context.count / this.clusterData.length;
        const hue = ~~interpolate(u, 90, 0);
        const size = ~~interpolate(u, 30, 50);
        return {
          backgroundColor: `hsla(${hue}, 100%, 50%, 0.6)`,
          width: `${size}px`,
          height: `${size}px`,
          lineHeight: `${size}px`,
          borderRadius: `50%`,
          border: `2px solid #fff`,
          boxShadow: `0 0px 2px hsla(${hue}, 100%, 50%, 1)`,
          color: `hsla(${hue}, 100%, 90%, 1)`,
          fontSize: '18px',
          textAlign: 'center',
        }
      },
      getClusterOptions(context) {
        const size = Math.round(
          30 + Math.pow(context.count / this.clusterData.length, 1 / 5) * 20
        );
        return {
          offset: [-size / 2, -size / 2],
        };
      },
      getMarkerOptions(point) {
        return {
          // offset: [-15, -15]
          offset: [0, 0]
        };
      },
      formatData(res) {
        const mapData = [];
        const clusterData = [];
        const { data = [], totalSize } = res;

        data.forEach((item, index) => {
          const hue = color[index > 9 ? 10 : index];
          item.color = hue;
          item.index = index;
          item.percentage = Number((item.listSize / totalSize * 100).toFixed(2));
          item.warehouseGroupInfoList && item.warehouseGroupInfoList.forEach(val => {
            val.color = hue;
            mapData.push({
              ...val,
              pId: index,
              groupName: item.groupName,
              lnglat: val.point
            });
          })

          clusterData.push(item);
        })

        const groupList = clusterData.slice(0, 10);

        const total = clusterData.slice(10).reduce((pre, cur) => {
          return cur.listSize + pre;
        }, 0);

        this.otherInfo = {
          listSize: total,
          percentage: Number((total / totalSize * 100).toFixed(2)),
          color: color[10]
        }

        this.originData = { clusterData, mapData, groupList };

        return {clusterData, mapData, groupList};
      }
    },
    mounted() {
      this.$api.base.warehouse_list().then(res => {
        if( res.code == 200 ) {
          const { clusterData, mapData, groupList } = this.formatData(res.data || {});
          this.clusterData = clusterData;
          this.mapData = mapData;
          this.groupList = groupList;
        } else {
          this.$alert('当前登录状态已失效，请重新登录', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              localStorage.removeItem('token')
              this.$router.push("/")
            }
          });
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  .viewport {
    position: relative;
    width: 100vw;
    height: 100vh;

    .custom-marker {
      background-color: hsla(180, 100%, 50%, 0.7);
      height: 24px;
      width: 24px;
      border: 1px solid hsl(180, 100%, 40%);
      border-radius: 12px;
      box-shadow: hsl(180, 100%, 50%) 0px 0px 1px;
      text-align: center;
    }

    .collapse-container {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 260px;
      padding: 0 10px 10px 10px;
      background: #fff;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        text-align: left;
        border-bottom: 1px solid #eee;
      }

      .collapse-inner {
        padding-top: 10px;
        .collapse-item {
          padding-bottom: 10px;

          &:last-child {
            padding-bottom: 10px;
          }

          .warehouseName {
            // padding-bottom: 6px;
            span {
              font-size: 14px;
            }
            span:nth-child(1) {
              display: inline-block;
              padding-right: 5px;
              // width: 45px;
            }
          }
        }
      }
    }

    .search-container {
      position: absolute;
      left: 10px;
      top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      background: #fff;
      border-radius: 5px;
    }

    .popup-container {
      width: 360px;
      padding: 10px;
      background: #fff;
      box-shadow:1px 1px 15px #e2e1e1;

      .title-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        padding-bottom: 8px;
        border-bottom: 1px solid #eee;

        span {
          font-size: 13px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis
        }

        .el-icon-close:before {
          color: #ccc;
          cursor: pointer;
        }
      }

      .popup-inner {
        padding-top: 8px;
        .item-inner {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding-bottom: 5px;

          span {
            font-size: 12px;
          }

          span:nth-child(1) {
            width: 60px;
          }

          span:nth-child(2) {
            flex:1;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

          &:last-child {
            padding: 0;
          }
        }
      }

    }

    .mark-point {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border: 3px solid #fff;
      background: pink;
      border-radius: 50%;
      box-shadow:1px 1px 15px #d0d0d0;
      -moz-box-shadow:1px 1px 15px #d0d0d0;
      -webkit-box-shadow:1px 1px 15px #d0d0d0; 

      &:hover {
        opacity: .8;
      }
      
      i {
        font-size: 14px;
        color: #fff;
      }
    }
  }
</style>

<style lang="less">
  .el-divider--horizontal {
    margin: 10px 0;
  }
  .custom-popper {
    .el-select-dropdown__item span {
      font-size: 14px;
    }
  }
  .collapse-inner {
    .el-progress-bar {
      padding-right: 60px;
      margin-right: -60px;
    }
  }
</style>